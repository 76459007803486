import $ from 'jquery';

import fitElements from 'utils/fit_elements';

$().ready(() => {

    /**
     * Logic for dynamically populating the `more` drop down option with menu items that
     * are unable to fit in the menu bar.
     *
     * The menu is created on the basis of Foundation tabs. Each menu item link is a `li`. Each of these contains
     * a data attribute `data-panel` that specifies the id of the tab panel that it corresponds to. This
     * corresponding tab panel is where sub-menu links and related articles will be shown.
     */

    var container = ".anvil-main-menu__tabs";
    var items = ".anvil-main-menu__item";
    var main_menu_more_button = $('#mainMenuSectionMore');
    var main_menu_more_panel = $(main_menu_more_button.attr('data-panel'));
    var main_menu_more_panel_content = main_menu_more_panel.find('.section-menu');

    // All menu items that have sub-menu items should be added to this container.
    var page_links_container = $(main_menu_more_panel_content).find('.section-menu__page-container');

    // This contains all menu items that do not have any children.
    var list_links_container = $(main_menu_more_panel_content).find('.section-menu__external-container');

    /**
     * Method for moving menu items that do no fit into the bar to the 'more' drop down option.
     *
     * @param hidden: The list of elements that are unable to fit into the menu bar.
     */
    function handleOverflow(hidden) {
        if (hidden.length < 1) {
            main_menu_more_button.hide();
        } else {
            main_menu_more_button.show();
        }

        // Wipe any links from the 'more' menu
        page_links_container.empty();
        list_links_container.empty();

        $.each(hidden, function () {
            var panel_selector = $(this).attr('data-panel');
            var panel = $(panel_selector);

            // The parent section name
            var panel_section_title = $(this).text();
            // The parent section link
            var panel_section_link = $(this).find('a').attr('href');

            var parent_section_link = $('' +
                '<div class="section-menu__title">' +
                '<a href="' + panel_section_link + '">' + panel_section_title + '</a><' +
                '/div>'
            );

            // If there is a panel then the hidden menu item contained sub-menu items. In this case
            // the parent menu item needs to be added along with its children menu items to the div
            // containing the other page links.
            if (panel.length != 0) {
                var copied_panel_sections = panel.find('.section-menu__list').clone();

                var section_block = $('<div class="section-menu__panel section-menu__panel--divider column"></div>');
                section_block.append(parent_section_link);
                section_block.append(copied_panel_sections);

                page_links_container.append(section_block);
            } else {
                list_links_container.append(parent_section_link);
            }
        });
    }

    /**
     * Helper function for dynamically resizing the menu items to fit in the menu bar.
     */
    function fitMenuItems() {
        var menu_items = fitElements(
            container,
            items,
            {
                offsetAmount: -remUnit * (numRems + 4),
            }
        ).trigger();
        handleOverflow(menu_items.hidden);
    }

    let mainMenuSearch = document.getElementById("mainMenuSearch");
    let searchButton = document.getElementById("searchButton");
    let searchForm = document.getElementById('searchForm');
    let numRems = 10;
    // Rem units determine width of main menu search box
    let remUnit = parseInt(window.getComputedStyle(document.documentElement)['font-size']);

    function handleOnBlur () {
        // If the search bar is empty, then close it when the user clicks off of it.
        if (!mainMenuSearch.value.trim()) {
            mainMenuSearch.style.width = '0';
        }
    }

    function handleOnFocus () {
        // The search bar should expand to the desired width when the user clicks it.
        mainMenuSearch.style.width = `${numRems}rem`;
    }

    // Using onClick causes a race condition with the onFocus event, leading to inconsistent events.
    function handleOnMouseUp () {
        // If the input is not empty, then clicking on the magnifying glass should trigger a search.
        if (mainMenuSearch.value.trim()) {
            searchForm.submit();
        } else {
            // Otherwise do nothing.
            return false;
        }
    }

    if (mainMenuSearch) {
        mainMenuSearch.onfocus = () => handleOnFocus();
        mainMenuSearch.onblur = () => handleOnBlur();
        searchButton.onmouseup = () => handleOnMouseUp();
    }

    // Only shows the sections in the menu that can fully fit in the container.
    fitMenuItems();
    $(window).resize(fitMenuItems);

});

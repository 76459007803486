import $ from 'jquery';

function markTabActive(tab_ele, active) {
    tab_ele = $(tab_ele);
    var tab_panel_selector = tab_ele.attr('data-panel');
    var tab_panel = $(tab_panel_selector);

    if (active) {
        tab_ele.addClass('is-active');
    } else {
        tab_ele.removeClass('is-active');
    }
    if (tab_panel) {
        // If the panel won't contain images, it doesn't stretch full width
        // and should be positioned directly under the tab.
        var tab_no_content = tab_panel.children('.section-menu--no-content');
        if (tab_no_content) {
            tab_no_content.first().css({
                'left': tab_ele.offset().left + 'px',
                'min-width': tab_ele.width()
            });
        }

        if (active) {
            tab_panel.addClass('is-active');
        } else {
            tab_panel.removeClass('is-active');
        }
    }
}


// Show the menu dropdown
$(document).ready(() => {
    $('.anvil-main-menu__tabs-title').mouseenter(function () {
        // Hide any currently active menu dropdowns.
        var active_tabs = $('.anvil-main-menu__tabs-title.is-active');
        $.each(active_tabs, function () {
            markTabActive(this, false);
        });

        // Make the selected tab active
        markTabActive(this, true);
    });

    $('.anvil-main-menu__content').mouseleave(function () {
        var active_menu = $('.anvil-main-menu__tabs-title.is-active');
        $.each(active_menu, function () {
            markTabActive(this, false);
        });
    });
});

// This is the entrypoint for modules that are required to be loaded by non-bundled JS on the site
// This entrypoint is blocking, it should be kept as minimal as possible.

import $ from 'jquery';

// Put jquery on the window so that non bundled code has access to jQuery
// The expose-loader was exposing a different instance of jQuery so `$.on` events were not being
// dispatched correctly.
window.$ = $;
window.jQuery = $;

import 'foundation-sites';
import './menu';
import * as Utils from './utils';
import dynamicAnalytics from "./utils/dynamic_analytics";

// Run the analytics so that it will trigger the page load analytics.
dynamicAnalytics()

// These modules/objects will be exposed on the `AnvilLegacy` library.
export {
    Utils,
    dynamicAnalytics
};

import jQuery from 'jquery';

/**
 * Check if the element is in the screen viewport.
 *
 * @param el - the element whose visibility should be determined.
 * @returns {boolean} True when the element is on the screen, false otherwise.
 */
function isElementInViewport(el) {
    // unwrap jQuery if needed
    if (el instanceof jQuery) {
        el = el[0];
    }
    let rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

/**
 * Function that will return true if the element is at least partially visible on the screen.
 *
 * @param elm - The element to check the visibility of.
 * @returns {boolean} true if it can be seen, false otherwise.
 */
export function isElementAtLeastPartiallyVisible(elm, verticalPostionBuffer=0) {
  // unwrap jQuery if needed
  if (elm instanceof jQuery) {
      elm = elm[0];
  }
  let rect = elm.getBoundingClientRect();
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  let bufferedViewHeight = viewHeight + verticalPostionBuffer;
  return !(rect.bottom + verticalPostionBuffer < 0 || rect.top - bufferedViewHeight >= 0);
}

/**
 * Creates a function that can be called to check if the specified element is in the viewport.
 *
 * @param el - element whose visibility should be checked.
 * @param callback - The function to be called when the visibility has changed. Passed two arguments:
 *  1. A boolean for whether or not the element is currently visible or not.
 *  2. A boolean that denotes whether a virtual page view has already been dispatched. True if no virtual page views
 *      have been sent yet.
 * @param visibilityMode - The visibility function to use:
 *  * 'partial' - will consider elements to be visible if any part of the element is visible on the screen.
 *  * 'full' - will only consider elements to be visible if all of the element is on the screen.
 * @returns {Function}
 */
export default function onVisibilityChange(el, callback, visibilityMode='full', verticalPostionBuffer=0) {
    let old_visible;
    let visibilityFunction;
    if (visibilityMode === 'full'){
      visibilityFunction = isElementInViewport;
    } else if (visibilityMode === 'partial') {
      visibilityFunction = isElementAtLeastPartiallyVisible;
    }
    return function (isFirstTime) {
        let visible = visibilityFunction(el, verticalPostionBuffer);
        if (visible !== old_visible) {
            old_visible = visible;
            if (typeof callback === 'function') {
                return callback(visible, isFirstTime);
            }
        }
        return false;
    }
}

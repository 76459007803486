import $ from 'jquery';
import {debounce} from 'throttle-debounce';
import mutationObserver from './mutation_observer';
import 'jquery.actual';

/**
 * Function that dynamically hides and shows elements that cannot fit into the specified container.
 *
 * @param container_selector - The jquery selector string used to find the container element. This element
 *  will be used to determine the max size allowed for the item elements to fit into.
 * @param item_selector - The jquery selector used to find the items that should be hidden or shown conditionally
 *  if they do not fit into the specified container.
 * @param options - Optional json object containing the following additional settings
 *
 *  hideTarget - [Default: item_selector] The element that should be hidden if the item specified by item_selector
 *      does not fit.
 *
 *  checkHeight - [Default: false] If specified the height will be used as the condition for making items hidden, otherwise
 *      it will use the width of the container.
 *
 *  offsetAmount - [Default: 0] The distance in px from the end of the container that should be treated as the bounds for the
 *      items being able to fit. An offset amount of `-40` would mean that we would treat the container as if
 *      it was 40 px shorter than its actual size.
 *
 *  hideOverflow - [Default: true] Whether any elements that are found to not fit inside the container should
 *      be hidden or not.
 *
 *  saveSize - [Default: false] Whether the size should be saved as a data attribute, specified by sizeAttributeName,
 *      for elements that are to be hidden.
 *
 *  sizeAttributeName - [Default: 'data-original-size'] The name of the attribute that should be used to get the
 *      size of the element that should be used to determine whether it can fit into the bounds of the container.
 *
 *  targetBuffer - [Default: 0] Add the given amount to the width/height of the element whose size is to be calculated.
 *
 *
 *  @return json - Return a json containing lists of the visible and hidden elements.
 */
export default function fitElements(container_selector, item_selector, options) {

    let config = {
        hideTarget: item_selector,
        checkHeight: false,
        offsetAmount: 0,
        hideOverflow: true,
        sizeAttributeName: 'data-original-size',
        saveSize: false,
        targetBuffer: 0,
        showMore: '',
        moreMenuUl: ''
    };

    $.extend(true, config, options);

    let showMoreContainer = $(config.showMore);

    if(config.showMore) {
        config.hideOverflow = false;
    }

    if(config.moreMenuUl) {
        config.moreMenuUl = $(config.moreMenuUl);
    }

    /**
     * Will try and get the width/height from a property on the element. Will fallback to using the
     * outer(Width/Height).
     *
     * @param ele - The element whose size should be calculated.
     * @returns {int}
     */
    function getSize(ele) {
        let $ele = $(ele);
        let size;
        if(config.checkHeight) {
            size = $ele.prop("height");
            return size ? size : $ele.actual('outerHeight');
        }
        size = $ele.prop("width");
        return size ? size : $ele.actual('outerWidth');
    }

    function getDimension(ele, extra=0) {
        let size = getSize(ele);
        let saved_size = $(ele).attr(config.sizeAttributeName);
        if (saved_size !== undefined) {
            saved_size = parseInt(saved_size, 10);
            // Don't use the saved size if it is zero.
            if (saved_size) {
                return parseInt(saved_size, 10) + extra;
            }
        }
        return size + extra;
    }

    /**
     * Save the original size as a data attribute.
     *
     * @param ele - the element whose size should be saved.
     */
    function saveDimension(ele) {
        let size = getSize(ele);
        $(ele).attr(config.sizeAttributeName, size);
    }


    /**
     * Run a check on the elements to see which ones can be shown/hidden.
     *
     * @returns {{visible: Array, hidden: Array}}
     */
    function trigger() {
        let visible_elements = [];
        let hidden_elements = [];

        $(container_selector).each(function () {
            let max_allowed_size = getDimension(this) + config.offsetAmount;
            let total_child_size = 0;
            // Loop through all of the specified children items that are found inside the container.
            $(this).find(item_selector).each(function () {
                let target = $(this).closest(config.hideTarget);
                let base_item_size = getDimension(this);

                // Only attempt to hide/show the item if it has a size.
                if (base_item_size > 0) {
                    // Keep a running total of the width for the children elements
                    total_child_size += base_item_size + config.targetBuffer;
                    if (total_child_size > max_allowed_size) {
                        // Add the current element along with any matching preceding elements to be hidden to
                        // an array of all hidden objects
                        hidden_elements.push(target);
                        let proceeding_hidden_items = target.nextAll(config.hideTarget).get();
                        hidden_elements = hidden_elements.concat(proceeding_hidden_items);

                        if (config.saveSize && !$(this).attr(config.sizeAttributeName)) {
                            saveDimension(this, config.targetBuffer);
                            $.each(proceeding_hidden_items, function () {
                                saveDimension(this, config.targetBuffer);
                            });
                        }

                        if (config.hideOverflow) {
                            target.hide();
                            $(proceeding_hidden_items).hide(); //hide proceeding items as well
                        }
                        return false;
                    }
                    else {
                        // Add to array of elements being displayed.
                        visible_elements.push(target);
                        // Item fits so show it.
                        target.show();
                    }
                }
            });
        });

        if(config.showMore){
            handleMoreDropdown(visible_elements, hidden_elements);
        }

        return {
            visible: visible_elements,
            hidden: hidden_elements
        };
    }

    function handleMoreDropdown(visible, hidden) {
        removeFromMoreMenu(visible);
        addToMoreMenu(hidden);
    }

    /**
     * Helper method for hiding and showing the 'more' button on the submenu.
     * Determines whether it should be shown/hidden based on the number
     * of children 'li' elements.
     */
    function setMoreMenuVisibility() {
        let number_menu_items = config.moreMenuUl.children('li').length;
        showMoreContainer.toggleClass('invisible', number_menu_items <= 0);
    }

    /**
     * Callback function for adding the items that should be hidden to the dropdown menu in the subheader.
     *
     * @param items - a list of elements that should be added to the dropdown menu.
     */
    function addToMoreMenu(items) {
        $.each(items, function () {
            if (!$.contains(config.moreMenuUl[0], $(this))) {
                // Save the original size of the item, so that the fitElements function
                // can use it to determine whether it would fit. When we add it to the menu it will
                // change size.
                if ($(this).attr(config.sizeAttributeName) === undefined) {
                    $(this).attr(config.sizeAttributeName, $(this).outerWidth());
                }
                config.moreMenuUl.append($(this));
            }
        });
        setMoreMenuVisibility();
    }

    /**
     * Callback function for removing the items that can fit in the subheader from the dropdown menu.
     *
     * @param items - the elements that can be shown in the submenu
     */
    function removeFromMoreMenu(items) {
        $.each(items, function () {
            if (!$.contains(showMoreContainer, $(this))) {
                showMoreContainer.before($(this));
            }
        });
        setMoreMenuVisibility();
    }

    return {
        trigger: trigger
    };
}

/**
 * Maps elements with the `data-fit` attribute to a `fitElements` instance.
 *
 * Available data attributes for configuring `fitElements`:
 *
 * - `data-fit-items`: maps to `item_selector`
 * - `data-fit-hide-target`: maps to `options.hideTarget`
 * - `data-fit-offset-amount`: maps to `options.offsetAmount`
 * - `data-fit-target-buffer`: maps to `options.targetBuffer`
 * - `data-fit-show-more`: the selector for the more dropdown container.
 * - `data-fit-show-more-ul`: the selector of the UL element where the hidden elements should be moved.
 * - `data-fit-save-size`: whether or not the original size should be saved as an attribute on the hidden item.
 *
 * @param element - The element containing the configuration data attributes needed to run the `fitElements` function.
 */
function registerFitElement(element) {
    element = $(element);
    let element_selector = element.data('fit');
    let container;
    if (element_selector) {
        container = $(element_selector);
    } else {
        container = $(element);
    }
    let fitItemsSelector = element.data('fitItems');
    let options = {
        hideTarget: element.data('fitHideTarget'),
        offsetAmount: element.data('fitOffsetAmount'),
        targetBuffer: element.data('fitTargetBuffer'),
        showMore: element.data('fitShowMore'),
        moreMenuUl: element.data('fitShowMoreUl'),
        saveSize: element.data('fitSaveSize')
    };
    let fitter = fitElements(container, fitItemsSelector, options);
    fitter.trigger();

    // Listen on resize events to recalculate the sizes.
    $(window).resize(() => setTimeout(function(){
        debounce(500, fitter.trigger());},
        50
    ));
}

$(document).ready(()=> {
    mutationObserver('[data-fit]', registerFitElement);
    $(document.body).find('[data-fit]').each((index, element) => registerFitElement(element));
});
/**
 * When an element tagged with a `data-page-tracker-url` is scrolled to the top of the page set the page URL and title to
 * match.
 *
 * This is used to keep the title and browser address bar in sync on infinite scroll pages.
 *
 * Tracker Element data attributes:
 * - `data-page-tracker-url`: required. The url to update to. It can be a relative or absolute URL.
 * - `data-update-title`: Corresponding page title.
 */

import dynamicAnalytics from "./dynamic_analytics";

/**
 * Callback for when a tracked element has become active.
 * @param {Element} el: element tagged with `data-page-tracker-url`
 */
function onBecomePrimaryPage(el) {
    let url = el.dataset.pageTrackerUrl;
    if (window.location.href===url || window.location.pathname===url)
        return;
    history.replaceState(null, '', url);

    let title = el.dataset.pageTrackerTitle;
    if (title) document.title = title;

    const analyticsPayload = JSON.parse(el.dataset.pageTrackerAnalyticsPayload);
    // Dynamically import analytics.
    dynamicAnalytics().then(
        analytics => analytics.page(analyticsPayload)
    ).catch(e => console.error("Could not import anvil analytics. " + e));
}

let intersectionObserver = new IntersectionObserver(
    (entries) => {
        entries
            .filter((entry) => entry.isIntersecting)
            .forEach((entry) => onBecomePrimaryPage(entry.target))
    }, {
        // Indicates a short box near the top of the page.
        rootMargin: "-10% 0px -85%",
    }
);

// make sure all future elements that match the selector get tracked.
const mutationObserver = new MutationObserver((mutations) => {
    mutations
        .filter((mutation) => mutation.type === 'childList')
        .forEach((mutation) => {
            Array.from(mutation.addedNodes)
                .filter((node) => node.nodeType === Node.ELEMENT_NODE)
                .forEach((el) => {
                    if (el.matches('[data-page-tracker-url]'))
                        intersectionObserver.observe(el);
                    el.querySelectorAll('[data-page-tracker-url]')
                        .forEach((el) => intersectionObserver.observe(el));
                })
        });
});

window.addEventListener('DOMContentLoaded', () => {
    mutationObserver.observe(document.body, {subtree: true, childList: true,});
    document.querySelectorAll('[data-page-tracker-url]')
        .forEach((el) => intersectionObserver.observe(el));
});

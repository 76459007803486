import $ from 'jquery';
import mutationObserver from './mutation_observer';

const SMART_COLLAPSE_DATA_ATTR = 'data-smart-collapse';
const SMART_COLLAPSE_FILTER = `[${SMART_COLLAPSE_DATA_ATTR}]`;

/**
 * Collapse the padding and margin for elements with the `data-smart-collapse` attribute that do not have any text or
 * any iframes.
 *
 * The `data-smart-collapse` attribute can be specified with a string of comma separated properties that should be set
 * to zero.
 *
 * excludeElement - Checks if the element or its children have 'data-collapse-exclude' attribute, indicating it
 * should not be collapsed.
 *
 * @param collapsibleElement - the element to collapse if empty
 */
function smartCollapse(collapsibleElement) {
    collapsibleElement = $(collapsibleElement);
    let collapsiblePropertiesString = collapsibleElement.attr(SMART_COLLAPSE_DATA_ATTR);

    // Default to collapsing the padding and margin
    if (!collapsiblePropertiesString) {
        collapsiblePropertiesString = 'padding,margin';
    }

    let collapsibleProperties = collapsiblePropertiesString.split(',');
    let containsText = Boolean(collapsibleElement.text().trim());
    let containsSpecialElements = (collapsibleElement.has('iframe').length !== 0) || collapsibleElement.has('[data-reactroot]').length !== 0 || collapsibleElement.has('[data-component]').length !== 0;
    let excludeElement = collapsibleElement.has('[data-collapse-exclude]').length !== 0;

    if (!containsText && !containsSpecialElements && !excludeElement) {
        // Element is empty so set the specified properties to zero
        for (let property of collapsibleProperties) {
            collapsibleElement.css(property.trim(), 0);
        }
    }
}

$(document).ready(() => {
    // Listen when any smart collapse elements are added to the page
    mutationObserver(SMART_COLLAPSE_FILTER, smartCollapse);
    $(document.body).find(SMART_COLLAPSE_FILTER).each((index, element) => smartCollapse(element));
});

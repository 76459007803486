import axios from "axios";
import "./adblock_detection";
import "./collapse_empty";
import "./hide_on_scroll";
import fitElements from "./fit_elements";
import mutationObserver from "./mutation_observer";
import "./infinite_scrolling";
import './infinite_scrolling_update_url';
import 'foundation-sites';
import './initialize_foundation';
import './visibility';
import $ from 'jquery';
import deep_link_redirect from './deep_link_redirect';


export {fitElements, deep_link_redirect};

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;
axios.defaults.headers['X-REQUESTED-WITH'] = 'XMLHttpRequest';

// Initialize foundation for any sticky content that is loaded dynamically.
document.addEventListener('infScrollFetchComplete', (e) => {
    $(e.detail.content).foundation();
});

/**
 * Since elements with `position: fixed` are positioned relative to the viewport,
 * we need to dynamically calculate where to place them.
 */
function setFixedPositions() {
    let content = $('#content');
    let contentWidth = content.outerWidth();
    let horizontalOffset = ($(window).width() - contentWidth) / 2;
    let verticalOffset =  $('.anvil-main-menu:visible').height() + $('#anvilHeader').height();

    content.css({
        'margin-top': `${verticalOffset}px`
    });

    $('#wallpaper-right').css({
        'left': contentWidth + horizontalOffset,
        'top': `${verticalOffset}px`
    });

    $('#wallpaper-left').css({
        'right': contentWidth + horizontalOffset,
        'top': `${verticalOffset}px`
    });
}

$(document).ready(setFixedPositions);
$(window).resize(setFixedPositions);

$(document).ready(() => {
    // Fix sticky containers loaded over AJAX.
    mutationObserver('[data-sticky-container]', (stickyContainer) => {
        $(stickyContainer).foundation();
        // Hack: Foundation only fully initializes a sticky instance when the `load.zf.sticky` event is triggered.
        // As of 5/17/17 this has not been fixed. See issue: https://github.com/zurb/foundation-sites/issues/8394
        $(window).trigger('load.zf.sticky');
    });
});


/**
 * Make a post request using axios.
 *
 * @param {String} url - the endpoint to POST to
 * @param {json} data - The data to submit
 * @param {json} config - Configuration options to pass to axios.
 * @returns {AxiosPromise}
 */
export let post = (url, data, config = null) => {
    return axios.post(url, data, config);
};

import isMobile from 'ismobilejs';

export default function (deep_link_uri) {
  $(document).ready(function () {
    const userAgent = window.navigator.userAgent;
    // TODO: Update this for iOS once universal linking is sorted out #1529
    if (isMobile(userAgent).android.device) {
      // If the app is installed this will redirect to the app.
      window.location.replace(deep_link_uri);
    }
  });
}

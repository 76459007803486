import $ from 'jquery';
import url from 'url';
import 'imports-loader?jQuery=>$!jquery-infinite-scroll-helper';
import {throttle} from 'throttle-debounce';
import mutationObserver from './mutation_observer';
import 'custom-event-polyfill';


/**
 * Handles loading more content on the page when the element with the `data-inf-scroll` data attribute reaches the
 * bottom of the page.
 *
 * There are two required elements that must be on the page for each infinite scroll configuration. The main element
 * which initializes `InfiniteScroll` and the element that is used to denote the next page.
 *
 * The main element can have the following data attributes:
 *
 * `data-inf-scroll` [REQUIRED] is used to specify which element the ajax content should be inserted into. If `data-inf-scroll`
 * has no value specified like `<div data-inf-scroll></div>` then the div `data-inf-scroll` is on will be used. Optionally,
 * it can be given a valid jQuery selector to target an element other than the one the data attribute is on. Like:
 * `<div id="ajax-container"></div><div data-inf-scroll="#ajax-container" data-inf-scroll-next"...">...</div>
 *
 * `data-inf-scroll-next` [REQUIRED] is required and is used to specify the selector to use to
 * find the element which specifies how to load more content. `data-inf-scroll-next` accepts any valid jQuery selector.
 *
 * `data-inf-scroll-buffer` (Optional) is used specify the pixel threshold that should be used to trigger a scroll event.
 * The buffer affects the distance the bottom of the window needs to be from the bottom of the target element in order to
 * begin to load more content. The higher the buffer the sooner the ajax content will be loaded.
 *
 *
 * The navigation element can be on the page several times. It will always use the last one in the DOM.
 *
 * The navigation element can have the following data attributes:
 *
 * `data-inf-scroll-url` [REQUIRED] is the only required attribute. It is used to specify the url for which a GET ajax
 * request should be made. The server should respond with the HTML that should be added to the page.
 *
 * `data-inf-scroll-stop` (Optional) is used to determine when the infinite scroll should stop.
 *
 *
 * Custom GET query parameters can be added to the ajax request that the scroller makes by adding two data attributes:
 * `data-inf-scroll-query` and `data-inf-scroll-query-value`. `data-inf-scroll-query` specifies the key to use for
 * the query parameter and `data-inf-scroll-query-value` specifies the value to use. So for if this element was on the page:
 * `<div data-inf-scroll-query="enable_debug" data-inf-scroll-query-value="true"></div>`, then `?enable_debug=true` would
 * be added to the infinite scroll request for more content. If multiple elements are found on the page that have use
 * the same `data-inf-scroll-query` key then the last one in the DOM will win.
 *
 *
 * Custom Events: (dispatched on `document`)
 * - `infScrollFetchStarted` : Sent right before the ajax request for more content is initiated.
 *      - event information: {
 *            detail: {
 *                scrollEl: jQuery()  // The scroll element this event was dispatched for.
 *            }
 *        }
 *
 * - `infScrollFetchComplete` : Sent when the scroller has received the ajaxed content, but before it has been
 *      added to the page.
 *      - event information: {
 *          detail: {
 *              scrollEl: jQuery(), // The scroll element this event was dispatched for.
 *              content: jQuery()   // The ajaxed content that was fetched.
 *          }
 *      }
 *
 *
 * # Examples
 *
 * ## Basic Example
 *
 * <!-- basic.html -->
 * <div data-inf-scroll-next='.pagination' data-inf-scroll>
 *     <div class='pagination' data-inf-scroll-url='/ajax-url'>
 *         // Loading animation/message HTML here
 *     </div>
 * </div>
 *
 * <!-- basic-ajax.html -->
 * ...
 * <div class='pagination' data-inf-scroll-url='/ajax-url?page=2'>
 *     // Loading animation/message HTML here
 * </div>
 *
 * // basic.js
 * new InfiniteScroll('#scrollElement');
 */
class InfiniteScroll {
    constructor(scrollElement) {
        this.getScrollInformation = this.getScrollInformation.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.getNextPageInfo = this.getNextPageInfo.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.shouldLoadMore = this.shouldLoadMore.bind(this);
        this.destroy = this.destroy.bind(this);

        // Data attribute names
        this.bufferData = 'infScrollBuffer';
        this.infScrollData = 'infScroll';
        this.infScrollNextData = 'infScrollNext';
        this.infScrollStopData = 'infScrollStop';
        this.infScrollUrlData = 'infScrollUrl';
        this.infScrollQueryData = 'infScrollQuery';
        this.infScrollQueryValueData = 'infScrollQueryValue';

        this.infiniteScrollElement = $(scrollElement);
        this.isLoading = false;
        this.defaultBuffer = 750;
        this.loadedClass = 'loaded';

        this.scrollFetchStartedEvent = 'infScrollFetchStarted';
        this.scrollFetchCompleteEvent = 'infScrollFetchComplete';

        this.scrollInfo = this.getScrollInformation();
        this.buffer = this.scrollInfo.buffer;
        this.ajaxContentDestination = this.scrollInfo.target;
        this.nextPageSelector = this.scrollInfo.nextSelector;

        this.scrollFunction = throttle(150, this.handleScroll);

        if (this.scrollInfo) {
            $(window).scroll(this.scrollFunction);
        }
    }

    /**
     * Parse the data attributes on the scroll element to get the information needed to setup infinite scrolling.
     *
     * @returns {{buffer: number, target: (*|jQuery|HTMLElement), nextSelector: *}}
     */
    getScrollInformation() {
        let buffer = this.infiniteScrollElement.data(this.bufferData);
        let scrollBottomBuffer = (buffer === undefined) ? this.defaultBuffer : buffer;
        let scrollTargetSelector = this.infiniteScrollElement.data(this.infScrollData);
        let scrollTarget = (scrollTargetSelector === undefined || scrollTargetSelector === '') ? this.infiniteScrollElement : $(scrollTargetSelector);
        let nextSelector = this.infiniteScrollElement.data(this.infScrollNextData);
        if (nextSelector === undefined) {
            console.error("Unable to set up data-inf-scroll element. `data-inf-scroll-next` is a required attribute.");
            return;
        }

        return {
            buffer: scrollBottomBuffer,
            target: $(scrollTarget),
            nextSelector: nextSelector
        }
    }

    /**
     * Get the next page element as well as the url for the next ajax request.
     *
     * @returns {{element: jQuery, url: *}}
     */
    getNextPageInfo() {
        // Hide any other loaders except the last one that might have been missed.
        $(this.nextPageSelector).filter(':not(:last)').each(function (index, element) {
            $(element).hide();
        }.bind(this));

        let nextPageElement = $(this.nextPageSelector).not(`.${this.loadedClass}`).last();

        // Can't find the next page information so we do not try and load the next page.
        if (!nextPageElement.length) {
            this.destroy(nextPageElement);
            return;
        }

        // Do not attempt to load the next page if the element is not visible. Prevents infinite scrollers created
        // under tabs from being triggered when they are not the active tab.
        if (!nextPageElement.is(':visible')) {
            return;
        }

        // We have loaded the last of the ajax
        if (nextPageElement.data(this.infScrollStopData) !== undefined) {
            this.destroy(nextPageElement);
            return;
        }

        let scrollAjaxUrl = nextPageElement.data(this.infScrollUrlData);
        if (scrollAjaxUrl === undefined) {
            console.error("Unable to set up data-infinite-scroll element. `data-scroll-url` is a required attribute.");
            return;
        }

        let scrollParams = this.getScrollParams();
        let parsedUrl = url.parse(scrollAjaxUrl, true);
        // When we format the URL back to a string it will not use `.query` to generate the query parameters
        // unless the search attribute is empty.
        delete parsedUrl.search;
        // Add any extra url params to the dictionary of query params on the URL object.
        if (scrollParams) {
            for (let key of Object.keys(scrollParams)) {
                parsedUrl.query[key] = scrollParams[key];
            }
        }

        return {
            element: nextPageElement,
            url: url.format(parsedUrl)  // turn url object back into valid url string
        }
    }

    /**
     * Get all additional query parameters that should be added to the url used to request more content.
     *
     * @returns {{json}}
     */
    getScrollParams() {
        let scrollParams = {};
        $(document.body).find('[data-inf-scroll-query]').each((index, element) => {
            let param = $(element).data(this.infScrollQueryData);
            let value = $(element).data(this.infScrollQueryValueData);
            if (typeof param !== 'undefined' && typeof value !== 'undefined') {
                scrollParams[param] = value;
            }
        });
        return scrollParams;
    }

    /**
     * Enable or disable the loading state of the scroller. This handles hiding and showing the loading message.
     *
     * @param isLoading - Whether or not the scroller is currently loading more content.
     * @param nextElement - The current next page element that is being processed.
     */
    toggleLoading(isLoading, nextElement) {
        if (nextElement === undefined) {
            nextElement = this.getNextPageInfo().element;
        }
        this.isLoading = isLoading;
        if (isLoading) {
            nextElement.show();
        } else {
            nextElement.hide();
            nextElement.addClass(this.loadedClass);
        }
    }

    /**
     * Function given to the scroll listener on the window.
     */
    handleScroll() {
        if (this.shouldLoadMore()) {
            let nextPageInfo = this.getNextPageInfo();
            if (nextPageInfo) {
                this.toggleLoading(true, nextPageInfo.element);

                let scrollContentFetchStarted = new CustomEvent(
                    this.scrollFetchStartedEvent,
                    {
                        detail: {
                            scrollEl: this.infiniteScrollElement
                        }
                    }
                );
                document.dispatchEvent(scrollContentFetchStarted);

                // Load more content
                $.get(nextPageInfo.url)
                    .done(function (data) {
                        let ajaxedHtml = $(data);
                        let scrollContentFetched = new CustomEvent(
                            this.scrollFetchCompleteEvent,
                            {
                                detail: {
                                    content: ajaxedHtml,
                                    scrollEl: this.infiniteScrollElement
                                }
                            }
                        );
                        document.dispatchEvent(scrollContentFetched);

                        this.ajaxContentDestination.append(ajaxedHtml);
                    }.bind(this))
                    .fail(function (error) {
                        console.error(error);
                    })
                    .always(function () {
                        this.toggleLoading(false, nextPageInfo.element);
                    }.bind(this))
                ;
            }
        }
    }

    /**
     * Handle checking whether more content should be loaded.
     *
     * Will not attempt to load more if there is already a request in progress. Will also only be true when the element
     * that is set to contain the ajax content get close to the bottom of the window.
     *
     * @returns {boolean}
     */
    shouldLoadMore() {
        if (this.isLoading) {
            return false;
        }
        return ($(window).scrollTop() + $(window).innerHeight() + this.buffer) >= this.ajaxContentDestination[0].scrollHeight;
    }

    /**
     * Scroller has finished loading all possible content, so we turn off the scroll listener.
     *
     * @param nextPageElement - the last page element that was loaded onto the page.
     */
    destroy(nextPageElement) {
        this.toggleLoading(false, nextPageElement);
        $(window).off("scroll", this.scrollFunction);
    }
}

$(document).ready(() => {
    mutationObserver('[data-inf-scroll]', (element) => new InfiniteScroll(element));
    $(document.body).find('[data-inf-scroll]').each((index, element) => new InfiniteScroll(element));

    // Fire scroll event once to handle edge case of the infinite scroll <div>
    // never crossing the bottom of the page (for pages with minimal content
    // that don't vertically extend far enough to ever scroll).
    $(window).scroll();
});



import 'blockadblock';
import $ from 'jquery';

$(document).ready(() => {
    // eslint-disable-next-line no-undef
    let myBlock = new BlockAdBlock({
        checkOnLoad: true,
        resetOnEnd: false,
        debug: false
    });

    myBlock.onDetected(() => {
        let blockDiv = $('#block-detector');

        if(blockDiv.length ) {
            blockDiv.show();
        }
    });
});

import $ from 'jquery';
import mutationObserver from './mutation_observer';
import 'foundation-sites';


/**
 * Hides the specified element when scrolling down and shows it again when scrolling up.
 */
export default class ScrollHider {

    /**
     * Create a ScrollHider instance.
     *
     * @param {Element} scrollListenerTarget - the element that should be used to listen for scroll events on.
     * @param {Element} elementToHide - The element that should be hidden and shown once scrolling begins.
     * @param {String} hideClass - The class that should be added to the element when it should be hidden.
     * @param {String} showClass - The class that should be added to the element when it should be shown.
     * @param {Integer} threshold - Add a scroll buffer to delay the transition between open and closed.
     * @param {String} enabledBreakpoints - A space separated string containing the foundation breakpoint sizes at which
     *  the scroll hider should be enabled.
     */
    constructor(scrollListenerTarget, elementToHide, hideClass='menu-hinge-out', showClass='menu-hinge-in',
                threshold=55, enabledBreakpoints='small medium') {
        this.previous_position = null;
        this.$scrollListenerTarget = $(scrollListenerTarget);
        this.$elementToHide = $(elementToHide);
        this.hideClass = hideClass;
        this.showClass = showClass;
        this.threshold = threshold;
        this.breakpointsList = enabledBreakpoints.split(' ');

        this._checkIfOnBreakpoint = this._checkIfOnBreakpoint.bind(this);
        this._handleScroll = this._handleScroll.bind(this);

        // Listen for scroll events so that we can show/hide the submenu header on scroll up/down.
        this.$scrollListenerTarget.on('scrollme.zf.trigger', this._handleScroll);
    }

    /**
     * Only
     *
     * @returns {boolean}
     * @private
     */
    _checkIfOnBreakpoint() {
        for (let breakpoint of this.breakpointsList) {
            if (breakpoint === Foundation.MediaQuery.current) {
                return true;
            }
        }
        return false;
    }

    _handleScroll(event, element, offset) {
        if (this._checkIfOnBreakpoint()) {
            if (!this.previous_position) {
                this.previous_position = offset;
            } else {
                let is_visible = this.$elementToHide.is(":visible");
                let animating_in = this.$elementToHide.hasClass(this.showClass);
                let animating_out = this.$elementToHide.hasClass(this.hideClass);
                // Delay the transition, needed due to sticky header moving content causing an unneeded
                // scroll event.
                if (offset < (this.previous_position - this.threshold) && !is_visible && !animating_in) {
                    Foundation.Motion.animateIn(
                        this.$elementToHide,
                        this.showClass
                    );
                } else if (offset > (this.previous_position + this.threshold) && is_visible && !animating_out) {
                    Foundation.Motion.animateOut(
                        this.$elementToHide,
                        this.hideClass
                    );
                }
                this.previous_position = offset;
            }
        }
    }
}


/**
 * Creates and ElementHider instance for an element.
 *
 * The element must have the `data-scroll-hider` and `data-scroll-hider-listener` attributes.
 *
 * Can optionally have the following attributes: `data-scroll-hider-hide-class`, `data-scroll-hider-show-class`,
 * `data-scroll-hider-threshold`, `data-scroll-hider-breakpoints`.
 *
 * @param element - The element the hider should be initialized for.
 */
function setupHider(element) {
    let $element = $(element);
    let scrollTarget = $element.data('scrollHiderListener');
    let elementToHide = $element.data('scrollHider') || $element;

    if (!scrollTarget) {
        console.error("[data-hider] element must specify an element whose scrolling should be monitored by " +
            "specifying the [data-hider-scroll] attribute.");
        return;
    }

    let hideClass = $element.data('scrollHiderHideClass');
    let showClass = $element.data('scrollHiderShowClass');
    let threshold = $element.data('scrollHiderThreshold');
    let breakpoints = $element.data('scrollHiderBreakpoints');

    return new ScrollHider(scrollTarget, elementToHide, hideClass, showClass, threshold, breakpoints);
}


$(document).on('ready', function () {
    mutationObserver('[data-scroll-hider]', (index, element) => setupHider(element));
    $(document).find('[data-scroll-hider]').each((index, element) => setupHider(element));
});